import styled from "styled-components";

import blast from "../assets/partners/blast.svg";
import thruster from "../assets/partners/thruster.svg";

interface PartnerType {
  name: string;
  logo: string;
  link: string;
}

const partners: PartnerType[] = [
  {
    name: "Blast",
    logo: blast,
    link: "https://blast.io/",
  },
  {
    name: "Thruster",
    logo: thruster,
    link: "https://www.thruster.finance/",
  },
];

const StyledPartnerships = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  padding: 10rem 0;
  @media (max-width: 600px) {
    padding: 8rem 4rem;
  }
`;

const Header = styled.h4`
  position: relative;
  font-weight: 600;
  color: var(--main);
  max-width: 40rem;
  text-align: center;

  font-size: 2.8rem;
  line-height: 4.3rem;
  @media (max-width: 600px) {
    font-size: 2.3rem;
    line-height: 3.8rem;
  }
`;

const Partners = styled.div`
  width: 100%;
  max-width: 105rem;
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4.3rem;
  margin-top: 7rem;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 4rem;
    margin-top: 5rem;
  }
`;

const PartnerLink = styled.a`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Partner = styled.img`
  max-width: 100%;
  max-height: 6.7rem;
`;

const Partnerships = () => {
  return (
    <StyledPartnerships>
      <Header>Integrations</Header>
      <Partners>
        {partners.map((p, i) => (
          <PartnerLink key={i} href={p.link} target="_blank">
            <Partner src={p.logo} alt={`${p.name} Logo`} />
          </PartnerLink>
        ))}
      </Partners>
    </StyledPartnerships>
  );
};

export default Partnerships;
