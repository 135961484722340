import styled from "styled-components";
import { LAUNCH, TWITTER_LINK } from "../app/constants";
import CandleAnimation from "./CandleAnimation";
import { Link } from "react-router-dom";

const StyledHero = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100dvh - 7.8rem);
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 7.8rem;
  overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  padding: 0 4rem;
  @media (max-width: 600px) {
    padding: 0 2rem;

    animation: content 2s forwards;
    animation-delay: 1.5s;
    @keyframes content {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-95%);
      }
    }
  }
`;

const Header = styled.h1`
  font-weight: 700;
  max-width: 70rem;
  text-align: center;
  color: var(--primary);

  font-size: 7rem;
  line-height: 7rem;
  @media (max-width: 600px) {
    font-size: 3.8rem;
    line-height: 5.2rem;
  }
`;

const Subheader = styled.h2`
  font-weight: 400;
  color: var(--main);
  margin-top: 1.7rem;
  max-width: 60rem;
  text-align: center;

  font-size: 1.9rem;
  line-height: 3rem;
  @media (max-width: 600px) {
    font-size: 1.6rem;
    line-height: 2rem;
    max-width: 80%;
  }
`;

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  color: var(--bg);
  box-shadow: 4px 4px 0px #b6b61b;
  font-weight: 500;
  cursor: pointer;

  height: 6.4rem;
  padding: 0 7rem;
  font-size: 1.9rem;
  margin-top: 4.2rem;
  @media (max-width: 600px) {
    height: 5rem;
    padding: 0 6rem;
    font-size: 1.6rem;
    margin-top: 3.3rem;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  color: var(--bg);
  box-shadow: 4px 4px 0px #b6b61b;
  font-weight: 500;
  cursor: pointer;

  height: 6.4rem;
  padding: 0 7rem;
  font-size: 1.9rem;
  margin-top: 4.2rem;
  @media (max-width: 600px) {
    height: 5rem;
    padding: 0 6rem;
    font-size: 1.6rem;
    margin-top: 3.3rem;
  }
`;

const Hero = () => {
  const isLive = LAUNCH.getTime() < Date.now();

  return (
    <StyledHero>
      <CandleAnimation />
      <Content>
        <Header>HIGHER</Header>
        <Subheader>The unit of account for Blast L2</Subheader>
        {isLive ? (
          <StyledLink to="get-higher">Get Higher</StyledLink>
        ) : (
          <Button href={TWITTER_LINK} target="_blank" rel="noreferrer">
            Follow Us
          </Button>
        )}
      </Content>
    </StyledHero>
  );
};

export default Hero;
