import styled from "styled-components";

const StyledAmountInput = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--bg);
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 500;
  height: 6.4rem;
  width: 31.5rem;
  justify-content: center;
  text-align: center;
  background: none;
  border: solid 3px var(--bg);
  background: rgba(0, 0, 0, 0.2);

  ::placeholder {
    color: rgba(0, 0, 0, 0.7);
  }
`;

interface Props {
  amount: number | null;
  setAmount: (amount: number) => void;
}

const AmountInput = ({ amount, setAmount }: Props) => {
  return (
    <StyledAmountInput>
      <Input
        type="number"
        placeholder="ETH amount"
        value={amount || ""}
        onChange={(e) => {
          setAmount(Number(e.target.value));
        }}
      />
    </StyledAmountInput>
  );
};

export default AmountInput;
