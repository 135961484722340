import styled from "styled-components";

import candle from "../assets/ui/candle.svg";

const StyledCandleAnimation = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 600px) {
    height: 66%;

    img:nth-child(2n) {
      display: none;
    }
  }
`;

const Candle = styled.img<{ x: number; height: number; delay: number }>`
  position: absolute;
  bottom: 0;
  left: ${(props) => props.x}%;
  height: ${(props) => props.height}%;
  animation: candle 2s forwards;
  transform: translateY(120%);
  animation-delay: ${(props) => props.delay}s;

  @keyframes candle {
    0% {
      transform: translateY(120%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const CandleAnimation = () => {
  const CANDLES = 20;
  return (
    <StyledCandleAnimation>
      {Array.from({ length: CANDLES }).map((_, index) => {
        const base = (index / CANDLES) * 100;
        const mod = Math.random() * (100 / CANDLES);
        const x = base + mod;
        let height = Math.random() * 100;
        if (x > 33 && x < 66) {
          height = height / 3;
        }
        const delay = Math.random() * 2;
        return (
          <Candle
            key={index}
            x={x}
            height={height}
            src={candle}
            delay={delay}
          />
        );
      })}
    </StyledCandleAnimation>
  );
};

export default CandleAnimation;
