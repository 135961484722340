import styled from "styled-components";
import { GITHUB_LINK, TELEGRAM_LINK, TWITTER_LINK } from "../app/constants";

interface SocialType {
  name: string;
  image: string;
  whiteImage: string;
  link: string;
}

const socials: SocialType[] = [
  {
    name: "Github",
    image: "https://img.icons8.com/ios-filled/50/002237/github.png",
    whiteImage: "https://img.icons8.com/ios-filled/50/FFFFFF/github.png",
    link: GITHUB_LINK,
  },
  {
    name: "Twitter",
    image: "https://img.icons8.com/ios-filled/50/002237/twitter.png",
    whiteImage: "https://img.icons8.com/ios-filled/50/FFFFFF/twitter.png",
    link: TWITTER_LINK,
  },
  {
    name: "Telegram",
    image: "https://img.icons8.com/ios-filled/50/002237/telegram.png",
    whiteImage: "https://img.icons8.com/ios-filled/50/FFFFFF/telegram.png",
    link: TELEGRAM_LINK,
  },
];

const StyledSocials = styled.div`
  display: flex;
  margin-right: 5.7rem;

  @media (max-width: 600px) {
    display: none;
  }
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 1.7rem;
  margin-left: 2rem;
`;

const SocialImage = styled.img`
  width: 100%;
`;

interface Props {
  white?: boolean;
}

const Socials = ({ white }: Props) => {
  return (
    <StyledSocials>
      {socials.map((social, index) => {
        if (!social.link) return null;
        return (
          <SocialLink
            key={index}
            href={social.link}
            target="_blank"
            rel="noreferrer"
          >
            <SocialImage src={white ? social.whiteImage : social.image} />
          </SocialLink>
        );
      })}
    </StyledSocials>
  );
};

export default Socials;
