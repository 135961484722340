import styled from "styled-components";
import useHasPremium from "../app/web3/views/use-has-premium";

import background from "../assets/backgrounds/footer.svg";
import useHasWhitelist from "../app/web3/views/use-has-whitelist";
import usePresaleHigherBalance from "../app/web3/views/use-presale-higher-balance";
import useTotalRaised from "../app/web3/views/use-total-raised";
import AmountInput from "./AmountInput";
import { useState } from "react";
import { useWriteContract } from "wagmi";
import { PRESALE_ADDRESS, PRESALE_END } from "../app/constants";
import { parseEther } from "viem";

import presaleAbi from "../app/web3/abis/presale-abi.json";
import usePresaleETHBalance from "../app/web3/views/use-presale-eth-balance";

const StyledGetHigherPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-bottom: 7.8rem;
`;

const Background = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  background: var(--primary);
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 30rem;
  background: var(--primary);

  @media (max-width: 600px) {
    height: calc(100dvh - 7.8rem - 7.8rem);
  }
`;

const OverlayTop = styled.div`
  background: var(--bg);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 51%;

  transform: translateY(0);
  animation: openUp 2s forwards;
  animation-delay: 0.3s;

  @keyframes openUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-105%);
    }
  }
`;

const OverlayBottom = styled.div`
  background: var(--bg);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 51%;

  transform: translateY(0);
  animation: openDown 2s forwards;
  animation-delay: 0.3s;

  @keyframes openDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(105%);
    }
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3rem;
  height: 100%;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Message = styled.div`
  font-weight: 700;
  max-width: 70rem;
  text-align: center;
  color: var(--primary);
  text-transform: uppercase;

  font-size: 7rem;
  line-height: 9rem;
  @media (max-width: 600px) {
    font-size: 3.8rem;
    line-height: 5.2rem;
  }
`;

const Button = styled.button`
  position: relative;
  cursor: pointer;
  background: var(--bg);
  color: var(--primary);
  box-shadow: 4px 4px 0px #b6b61b;
  text-transform: uppercase;

  font-size: 1.6rem;
  font-weight: 500;
  height: 6.4rem;
  width: 31.5rem;
  letter-spacing: 0.46000000834465027px;
  @media (max-width: 600px) {
    font-size: 1.3rem;
    height: 5rem;
    padding: 0 6rem;
    letter-spacing: 0.46000000834465027px;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 2.8rem;
  color: var(--bg);
  font-weight: 800;
  text-transform: uppercase;
  margin: 0.3rem 0;

  @media (max-width: 600px) {
    font-size: 2.3rem;
    margin: 1rem 0;
    text-align: center;
  }
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GetHigherPage = () => {
  const { isPending, writeContractAsync } = useWriteContract();
  const hasPremium = useHasPremium();
  const hasWhitelist = useHasWhitelist();
  const higherBalance = usePresaleHigherBalance();
  const ethDeposit = usePresaleETHBalance();
  const totalRaised = useTotalRaised();

  const [amount, setAmount] = useState(0);

  const connected =
    hasPremium !== null &&
    hasWhitelist !== null &&
    higherBalance !== null &&
    totalRaised !== null;

  const hasSomeWhitelist = hasPremium || hasWhitelist;
  const presaleEnded = new Date() > PRESALE_END;

  if (!connected) {
    return (
      <StyledGetHigherPage>
        <Message>Connect wallet to get higher</Message>
      </StyledGetHigherPage>
    );
  }

  return (
    <StyledGetHigherPage>
      <Container>
        <Background src={background} />
        <Content>
          <TextSection>
            {presaleEnded ? (
              <>
                <Text>Not enough sold during presale</Text>
                <Text>{`Your ETH: ${ethDeposit} ETH`}</Text>
              </>
            ) : (
              <>
                <Text>{`Your Higher: ${higherBalance}`}</Text>
                <Text>{`Your Whitelist: ${
                  hasPremium ? "Tranch 1" : hasWhitelist ? "Tranch 2" : "none"
                }`}</Text>
                <Text>{`Total raised: ${totalRaised} ETH`}</Text>
              </>
            )}
          </TextSection>
          <InputSection>
            {presaleEnded ? (
              ethDeposit &&
              ethDeposit > 0 && (
                <Button
                  disabled={isPending}
                  onClick={async () => {
                    await writeContractAsync({
                      address: PRESALE_ADDRESS,
                      abi: presaleAbi,
                      functionName: "claimETHBack",
                    });
                  }}
                >
                  {isPending ? "Loading..." : "Claim ETH back"}
                </Button>
              )
            ) : (
              <>
                {!hasSomeWhitelist && (
                  <AmountInput amount={amount} setAmount={setAmount} />
                )}
                <Button
                  disabled={isPending}
                  onClick={async () => {
                    if (hasSomeWhitelist) {
                      await writeContractAsync({
                        address: PRESALE_ADDRESS,
                        abi: presaleAbi,
                        functionName: "whiteListClaim",
                        value: hasPremium
                          ? parseEther("0.5")
                          : parseEther("0.15"),
                      });
                      return;
                    }

                    await writeContractAsync({
                      address: PRESALE_ADDRESS,
                      abi: presaleAbi,
                      functionName: "getHigher",
                      value: parseEther(amount.toString()),
                    });
                  }}
                >
                  {isPending ? "Loading..." : "Get higher"}
                </Button>
              </>
            )}
          </InputSection>
        </Content>
        <OverlayTop />
        <OverlayBottom />
      </Container>
    </StyledGetHigherPage>
  );
};

export default GetHigherPage;
