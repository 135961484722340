import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import Layout from "./Layout";
import LandingPage from "./pages/LandingPage";
import GetHigherPage from "./components/GetHigherPage";



const App = () => {
  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<LandingPage />} />
            <Route path="/get-higher" element={<GetHigherPage />} />
            <Route path="*" element={<LandingPage />} />
          </Route>
        </Routes>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
