import { useAccount, useReadContract } from "wagmi";

import presaleAbi from "../abis/presale-abi.json";
import { PRESALE_ADDRESS } from "../../constants";
import { bigNumberToNumber } from "../../../lib/number";

const usePresaleHigherBalance = () => {
  const { address } = useAccount();

  const { data } = useReadContract({
    abi: presaleAbi,
    address: PRESALE_ADDRESS,
    functionName: "higherTokenBalance",
    args: [address],
  }) as { data: bigint | null };

  if (typeof data === "undefined" || data === null) return null;

  return bigNumberToNumber(data, 18);
};

export default usePresaleHigherBalance;
