import styled from "styled-components";

import background from "../assets/backgrounds/footer.svg";

const steps: string[] = [
  "Supply is elastic and changes as market demand shifts",
  "Targets stable purchasing power by aligning with the price of ETH",
  "Automatic adjustments ensure supply matches economic conditions",
  "Your percentage of the network remains constant, despite supply changes",
];

const StyledHowDoesItWork = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: var(--primary);

  padding: 10rem 4rem;
  @media (max-width: 600px) {
    padding: 8rem 4rem;
  }
`;

const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background: var(--primary);
`;

const Header = styled.h4`
  position: relative;
  font-weight: 600;
  color: var(--bg);
  max-width: 37rem;
  text-align: center;

  font-size: 2.8rem;
  line-height: 4.3rem;
  @media (max-width: 600px) {
    font-size: 2.2rem;
    line-height: 4rem;
  }
`;

const Steps = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  margin-top: 2.4rem;
  max-width: 130rem;

  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.9rem;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2.4rem;
  }
`;

const Step = styled.div`
  position: relative;
  background: var(--bg);
  height: 100%;
  width: 100%;
  text-align: center;
  border: 1px dashed var(--border);
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2rem 2.4rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media (max-width: 600px) {
    padding: 1.6rem 2rem;
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
`;

const HowDoesItWork = () => {
  return (
    <StyledHowDoesItWork>
      <Background src={background} alt="How it works background" />
      <Header>How it works</Header>
      <Steps>
        {steps.map((step, index) => (
          <Step key={index}>{step}</Step>
        ))}
      </Steps>
    </StyledHowDoesItWork>
  );
};

export default HowDoesItWork;
