import { useBalance } from "wagmi";

import { PRESALE_ADDRESS } from "../../constants";
import { bigNumberToNumber } from "../../../lib/number";

const useTotalRaised = () => {
  const { data } = useBalance({
    address: PRESALE_ADDRESS,
  }) as { data: any };

  if (typeof data === "undefined" || data === null) return null;

  return bigNumberToNumber(data.value, 18);
};

export default useTotalRaised;
