import { Outlet } from "react-router-dom";
import styled from "styled-components";

import { WagmiProvider, createConfig, http } from "wagmi";
import { injected } from "wagmi/connectors";

import Header from "./components/Header";
import { blast } from "./app/constants";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const StyledApp = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--bg);
`;

const queryClient = new QueryClient();

const config = createConfig({
  chains: [blast],
  connectors: [injected()],
  transports: {
    [blast.id]: http("https://rpc.ankr.com/blast"),
  },
});

declare module "wagmi" {
  interface Register {
    config: typeof config;
  }
}

const Layout = (): JSX.Element => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <StyledApp>
          <Header />
          <Outlet />
        </StyledApp>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default Layout;
