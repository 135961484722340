import { defineChain } from "viem";

// Links
export const DOCS_LINK = null;
export const TWITTER_LINK = "https://twitter.com/Higher_Finance";
export const GITHUB_LINK = "https://github.com/HIGHER-power";
export const TELEGRAM_LINK = "https://t.me/higher_finance";
const EPOC = 1709672400;
export const LAUNCH = new Date(EPOC * 1000);
export const PRESALE_END = new Date(LAUNCH.getTime() + 1000 * 60 * 60 * 24 * 1);

// Contracts
export const PRESALE_ADDRESS = "0x3f5954d7fe80fc0c059251b2d1964d894c3d3ba1";
export const HIGHER_ADDRESS = "0x30D91d6473c281946b15bAB40Df578e542056479";

export const blast = defineChain({
  id: 81457,
  name: "Blast",
  network: "Blast",
  nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://rpc.ankr.com/blast"] },
    public: { http: ["https://rpc.ankr.com/blast"] },
  },
  blockExplorers: {
    default: { name: "BlastScan", url: "https://etherscan.io" },
  },
});
