import styled from "styled-components";
import Connector from "./Connector";
// import NavItems from "./NavItems";
import Socials from "./Socials";
import Countdown from "./Countdown";

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 7.8rem;
  padding: 0 9rem;
  background: var(--bg);

  @media (max-width: 600px) {
    padding: 0 2rem;
    justify-content: center;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const Header = () => {
  return (
    <StyledHeader>
      <Socials white />
      <Countdown />
      <RightSection>
        {/* <NavItems /> */}
        {/* <Gas /> */}
        <Connector />
      </RightSection>
    </StyledHeader>
  );
};

export default Header;
