import styled from "styled-components";

import governance from "../assets/benefits/governance.svg";
import noncustodial from "../assets/benefits/noncustodial.svg";
import permissionless from "../assets/benefits/permissionless.svg";

interface BenefitType {
  title: string;
  icon: string;
}

const benefits: BenefitType[] = [
  {
    title: "Decentralized",
    icon: governance,
  },
  {
    title: "Elastic",
    icon: permissionless,
  },
  {
    title: "Non-Dilutive",
    icon: noncustodial,
  },
];

const StyledBenefits = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  max-width: 150rem;

  padding: 10rem 0;
  @media (max-width: 600px) {
    padding: 8rem 4rem;
    flex-direction: column;
  }
`;

const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    margin: 2rem 0;
  }
`;

const Icon = styled.img`
  width: 15.4rem;
  filter: hue-rotate(186deg) saturate(2) brightness(1.15) contrast(1);
`;

const Label = styled.h4`
  font-weight: 600;
  font-size: 2rem;
  margin-top: 2.1rem;
`;

const Benefits = () => {
  return (
    <StyledBenefits>
      {benefits.map((benefit) => {
        return (
          <Benefit key={benefit.title}>
            <Icon src={benefit.icon} />
            <Label>{benefit.title}</Label>
          </Benefit>
        );
      })}
    </StyledBenefits>
  );
};

export default Benefits;
