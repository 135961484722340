import { useAccount, useReadContract } from "wagmi";

import presaleAbi from "../abis/presale-abi.json";
import { PRESALE_ADDRESS } from "../../constants";

const useHasPremium = () => {
  const { address } = useAccount();

  const { data } = useReadContract({
    abi: presaleAbi,
    address: PRESALE_ADDRESS,
    functionName: "premiumWhitelist",
    args: [address],
  }) as { data: bigint | null };

  if (typeof data === "undefined" || data === null) return null;

  return data > BigInt(0);
};

export default useHasPremium;
