import { useEffect, useState } from "react";
import styled from "styled-components";
import { LAUNCH, PRESALE_END } from "../app/constants";
import { useLocation } from "react-router-dom";
import { useAccount } from "wagmi";

const StyledCountdown = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.1rem;
  font-weight: 700;
  color: var(--primary);
  white-space: nowrap;
`;

const Countdown = () => {
  // eslint-disable-next-line
  const [tick, setTick] = useState(0);
  const { isConnected } = useAccount();
  const location = useLocation();

  const isPresale = location.pathname === "/get-higher";

  useEffect(() => {
    const interval = setInterval(() => {
      setTick(Math.random());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const now = new Date().getTime();

  const targetDate = isPresale ? PRESALE_END.getTime() : LAUNCH.getTime();

  const distance = targetDate - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));

  const hours = (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);

  const minutes = (distance % (1000 * 60 * 60)) / (1000 * 60);

  const seconds = (distance % (1000 * 60)) / 1000;

  const isLive = distance < 0;

  if (isLive && !isPresale) return null;
  if (!isConnected) return null;

  return (
    <StyledCountdown>
      {distance > 0 ? (
        <>
          {days}d {Math.floor(hours)}h {Math.floor(minutes)}m{" "}
          {Math.floor(seconds)}s
        </>
      ) : (
        <>Presale has ended</>
      )}
    </StyledCountdown>
  );
};

export default Countdown;
