import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --bg: black;
        --main: white;
        --sub: grey;
        --border: #25251d;
        --primary: #fefd02;
        --primary-dark: #656501;
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 10px;
        color: var(--main);
        font-family: "tachyon", sans-serif;

    }

    button {
        background: none;
        border: none;
        outline: none;
    }
    
    input {
        border: none;
        outline: none;

        ::-webkit-outer-spin-button {
            display: none;
        }
        ::-webkit-inner-spin-button {
            display: none;
        }
    }

    a {
        text-decoration: none;
    }
`;

const GlobalStyles = (): JSX.Element => {
  return <GlobalStyle />;
};

export default GlobalStyles;
