// import { InjectedConnector } from "@wagmi/core";
import styled from "styled-components";
import { useAccount, useConnect } from "wagmi";
import { shortenAddress } from "../lib/text";

interface ConnectorProps {
  showMobile?: boolean;
}

const StyledConnector = styled.button<ConnectorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  border: solid 1px var(--primary);
  height: 4.2rem;
  padding: 0 1.5rem;
  cursor: pointer;

  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;

  @media (max-width: 600px) {
    display: ${({ showMobile }) => (showMobile ? "flex" : "none")};
    margin: auto;
  }
`;

const Connector = () => {
  const { address, isConnected } = useAccount();
  const { connectors, connect } = useConnect();

  return (
    <StyledConnector
      onClick={() => connect({ connector: connectors[0] })}
      showMobile={!isConnected}
    >
      {isConnected && address ? shortenAddress(address) : "Connect wallet"}
    </StyledConnector>
  );
};

export default Connector;
